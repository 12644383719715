import React, { useEffect, useState } from 'react';


function Safeqr() {
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    // Function to detect platform and redirect accordingly
    const redirectBasedOnPlatform = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Check if the user agent indicates iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // Redirect to App Store URL for iOS
        window.location.href = 'https://apps.apple.com/in/app/simplyfy/id1669074717'; // Replace with your App Store URL
      } else if (/android/i.test(userAgent)) {
        // Redirect to Play Store URL for Android
        window.location.href = 'https://play.google.com/store/apps/details?id=com.proj.simplyfy'; // Replace with your Play Store URL
      } else {
        // Set state to show the welcome message if platform detection fails
        setShowWelcome(true);
      }
    };

    // Call the redirect function when the component mounts
    redirectBasedOnPlatform();
  }, []);

  return (
    <div style={styles.container}>
      {showWelcome ? (
        <>
          <h1 style={styles.header}>Welcome to safeqr.in</h1>
          <img 
            src='/qrcode.jpg' // Replace with your actual QR code image URL
            alt="QR Code"
            style={styles.qrCode}
          />
        </>
      ) : null}
    </div>
  );
}

// Styles for the container
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full height of the viewport
    backgroundColor: '#f8f9fa', // Light gray background
    color: '#343a40', // Darker text color
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
  },
  header: {
    fontSize: '2.5rem',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  qrCode: {
    width: '150px', // Set width for QR code
    height: '150px', // Set height for QR code
    border: '2px solid #343a40', // Border around QR code
    borderRadius: '10px', // Rounded corners
  },
};

export default Safeqr;
